<template>
  <v-container fluid>
      <v-row>
          <v-col>
            <v-dialog
            v-model="dialog"
            width="700"
            
            transition="dialog-bottom-transition"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs" v-on="on" @click="getCitiesReference()"><v-icon>mdi-plus</v-icon> Add Branch</v-btn>
            </template>
            <v-card>
                <v-toolbar
                dark
                outlined
                :class="isUpdate?'orange':'blue'+ 'accent-2'"
                elevation="1"
                >
                <v-btn
                    icon
                    
                    @click="dialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn  text @click="isUpdate? update():create()" >
                    <v-icon>{{isUpdate? 'mdi-check':'mdi-plus'}}</v-icon> {{isUpdate? 'Update Branch': 'Add Branch'}} </v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <v-form ref="branch">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" :rules="rules.stringRule" dense label="Branch Name" outlined v-model="branch.branch_name"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" :rules="rules.stringRule" dense label="Address" outlined v-model="branch.address"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="12">
                               <v-autocomplete :items="cityReference" item-text="city_name" item-value="city_id" dense outlined label="City Name" v-model="branch.city_id"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4" lg="4" sm="12">
                                <v-checkbox label="IsShow?"  v-model="branch.isShow"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
            </v-dialog>
            <v-card>
                <v-data-table :headers="headers" :items="branches" hide-default-footer  :items-per-page="20"  :loading="loading">
                    <template v-slot:top>
                        <v-text-field @focus="$event.target.select()"
                        v-model="table.search"
                        label="گەڕان"
                        class="mx-4"
                        ></v-text-field>
                    </template>
                    <template v-slot:[`item.isShow`]="{ item }">
                        {{item.isShow? 'Show': 'store'}}
                    </template>
                    
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon" >
                        <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon" @click="branch = removeProperty(item,['city_name'] ) ; dialog=true;getCitiesReference()">mdi-pencil</v-icon>
                        <v-icon large class="ml-2" v-else>mdi-pencil</v-icon>
                        </v-hover>
                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon" >
                            <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon" @click="remove(item.branch_id)">mdi-delete</v-icon>
                            <v-icon large class="ml-2" v-else >mdi-delete</v-icon>
                        </v-hover>
                    </template>
                </v-data-table>
                <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfBranches / 20)"  v-model="table.page"></v-pagination>
            </v-card>
          </v-col>
      </v-row>
      <notification/>
  </v-container>
</template>

<script>
export default {
    data() {
        return {
            table: {
                page: 1,
                numberOfBranches: 0,
                search: ''
            },
            dialog: false,
            cityReference: [],
            branch: {
                branch_id: 0,
                branch_name: '',
                address: '',
                isShow: false,
                city_id: 0
            },
            loading: false,
            branches: [],
            headers: [
                {text: 'Branch Name', value: 'branch_name', align: 'center'},
                {text: 'Address', value: 'address', align: 'center'},
                {text: 'isShow', value: 'isShow', align: 'center'},
                {text: 'City Name', value: 'city_name', align: 'center'},
                {text: 'کردارەکان', value: 'actions', align: 'center'},
            ],
            rules: {
                stringRule: [
                    (v) => {
                        return ( v != '' || 'please fill this field')
                    }
                ],
                numberRule: [
                    (v) => {
                        return ( v >= 0 || 'negative number invalid')
                    }
                ]
            }
        }
    },
    methods: {
        async getBranches(){
            try {
                this.loading = true
                const {data} = await this.axios.get('/branch', {params:{page:this.table.page, search: this.table.search}})
                this.branches = data.branches
                this.table.numberOfBranches = data.count
                this.loading = false
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
            }
        },
        async getCitiesReference(){
            try {
                const {data} = await this.axios.get('/city/forReference')
                this.cityReference = data
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
            }
        },
        
        async create(){
            if (this.$refs.branch.validate()) {
                try {
                    await this.axios.post('/branch', this.branch)
                    this.reset()
                    this.getBranches()
                } catch (error) {
                    console.log(error);
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', {type: 'error', text:`${error.response.data.field} is duplicate`})
                    }
                    this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
                }
            }
        },
        async update() {
            if (this.$refs.branch.validate()) {
                try {
                    await this.axios.patch('/branch/'+ this.branch.branch_id, this.branch)
                    this.getBranches()
                    this.reset()
                    this.dialog = false
                } catch (error) {
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', {type: 'error', text:`${error.response.data.field} is duplicate`})
                    }
                    this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
                }
            }
        },
        remove(id){
            this.$confirm('Are you sure to delete this branch', 'confirmation', 'question').then(async () => {
                try {
                    await this.axios.delete('/branch/'+id)
                    this.getBranches()
                } catch (error) {
                    this.emitter.$emit('notify', {type: 'error', text:'an error occur!'})
                }
            })
        },
        reset(){
            this.item = {
                branch_id: 0,
                branch_name: '',
                address: '',
                isShow: false,
                city_id: 0
            }
            this.$refs.branch.resetValidation();
        },
        removeProperty({...obj} = {}, fields){
            for (let i = 0; i < fields.length; i++)  delete obj[fields[i]]
            return obj
        }
    },
    created() {
        this.getBranches()
    },
    watch: {
        dialog(val){
            if (!val) {
                this.branchReference = []
                this.reset()
            }
        },
        'table.search': function () {
            this.getBranches()
        },
        'table.page': function () {
            this.getBranches()
        }
    },
    computed: {
        isUpdate(){
            return this.branch.branch_id
        }
    }
}
</script>

<style>

</style>